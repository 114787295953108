import {AbstractValidator} from "../AbstractValidator";

declare let $: any;
declare let window: any;

export class DoesNotMatchValidator extends AbstractValidator {

    public static validatorName: string = "doesNotMatch";

    public validate(): boolean
    {
        const matchAgainst = $(this.inputElement).parents('form').find('[name="'+ this.validateParam + '"]');

        if (matchAgainst.length > 0)
        {
            let matchValue: boolean = this.getInputValue() != matchAgainst.val();

            if (!matchValue && !window.doesNotMatchCustomTrigger)
            {
                window.doesNotMatchCustomTrigger = true;
                $(matchAgainst).trigger('focusout');
            }
            else
            {
                window.doesNotMatchCustomTrigger = false;
            }

            return matchValue;
        }

        return true;
    }
}
