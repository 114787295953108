
import {AbstractValidator} from "../AbstractValidator";

export class NonNumeric extends AbstractValidator {

	public static validatorName: string = "nonNumeric";

	public validate(): boolean
	{
		let inputValue = this.getInputValue();
		let inputLength = inputValue.length;

		let regEx = new RegExp('^[^0-9]+$');

		let valuePresent = (inputLength === 0);
		if (valuePresent) return true;

		return regEx.test(inputValue);
	}
}
