import {Config} from "./Entity/Config";
import {DI} from "../../DI";
import {AbstractModule} from "../AbstractModule";

declare let jsonConfig: Object;

export class ConfigModule extends AbstractModule
{
	private config: Config;

	public constructor(DI: DI)
	{
		super(DI);
	}

	public getConfig(): Config
	{
		if (!this.config)
		{
			// If JSON config not found in global scope with type of 'object'
			if (typeof jsonConfig != 'object')
			{
				// Log error and return null
				console.warn('No JSON config found in global scope - defaulting to empty object.');
				window['jsonConfig'] = {};
			}

			this.config = new Config(jsonConfig, this.getDI());
		}

		return this.config;
	}

	public searchArray(arrayKeyPath:string, array:Array<any> | Object):any
	{
		// Split path into array of keys
		let keysArray = arrayKeyPath.split(":");

		let keyCount = keysArray.length;

		let count = 1;

		// For each key
		for (let key of keysArray)
		{

			// If key exists
			if (array.hasOwnProperty(key))
			{
				// If this is the last key to be accessed
				if (count == keyCount)
				{
					// Return it
					return array[key];
				}

				if (!array[key])
				{
					console.error('Config key path does not exist: ' + arrayKeyPath + '. Check YAML indentation?');

					return null;
				}
				// Access key
				array = array[key];

				// Increment count
				count++;
			}
			else
			{
				return null;
			}
		}
	};

}
