import {AbstractComponent} from "ui-base/src/AbstractComponent";
// Import validator classes
import {RequiredValidator} from "./Validator/Required/RequiredValidator";
import {MinLengthValidator} from "./Validator/MinLength/MinLengthValidator";
import {MaxLengthValidator} from "./Validator/MaxLength/MaxLengthValidator";
import {EmailValidator} from "./Validator/Email/EmailValidator";
// Import constraint classes
import {PhoneConstraint} from "./Constraint/Phone/PhoneConstraint";
import {NumericConstraint} from "./Constraint/Numeric/NumericConstraint";
import {MaxLengthConstraint} from "./Constraint/MaxLength/MaxLengthConstraint";
import {DI} from "../../../../_resources/ts/DI/DI";
import {AbstractValidator} from "./Validator/AbstractValidator";
import {DummyValidator} from "./Validator/Dummy/DummyValidator";
import {NoSpacesValidator} from "./Validator/NoSpaces/NoSpacesValidator";
import {NumericOnlyValidator} from "./Validator/NumericOnly/NumericOnlyValidator";
import {PhoneValidator} from "./Validator/Phone/PhoneValidator";
import {PostalCodeValidator} from "./Validator/PostalCode/PostalCodeValidator";
import {UrlValidator} from "./Validator/Url/UrlValidator";
import {MaskConstraint} from "./Constraint/Mask/MaskConstraint";
import {AlphaConstraint} from "ui-base/src/Components/Form/_resources/ts/Constraint/Alpha/AlphaConstraint";
import {MoneyConstraint} from "ui-base/src/Components/Form/_resources/ts/Constraint/Money/MoneyConstraint";
import {NonNumericConstraint} from "ui-base/src/Components/Form/_resources/ts/Constraint/NonNumeric/NonNumericConstraint";
import {NonNumeric} from "ui-base/src/Components/Form/_resources/ts/Validator/NonNumeric/NumericOnlyValidator";
import {NonCommonPasswordValidator} from "ui-base/src/Components/Form/_resources/ts/Validator/NonCommonPassword/NonCommonPasswordValidator";
import {CreatePasswordValidator} from "ui-base/src/Components/Form/_resources/ts/Validator/CreatePassword/CreatePasswordValidator";
import {PasswordMatchValidator} from "ui-base/src/Components/Form/_resources/ts/Validator/PasswordMatchValidator/PasswordMatchValidator";
import {EmailMatchValidator} from "ui-base/src/Components/Form/_resources/ts/Validator/EmailMatchValidator/EmailMatchValidator";
import {DoesNotMatchValidator} from "./Validator/DoesNotMatchValidator/DoesNotMatchValidator";
import {FileExtensionMatchValidator} from "./Validator/FileExtensionMatchValidator/FileExtensionMatchValidator";

declare let $: any;

export abstract class AbstractFormComponent extends AbstractComponent {

    // if this is true the form will be submitted
    // default to true because not all forms have validation
    protected formValid: boolean = true;

    // Text to switch-out in the submit button while the form is being sent
    protected processingText: string = "Processing...";
    protected buttonData: any = null;
    protected lastClickedButton;
    protected submitted: boolean = false;
    // Whether submission is inflight or not
    protected submitting: boolean = false;
    // Whether the buttons should reset after submission
    protected shouldButtonsResetAttr: string = 'form-reset-buttons';

    public init()
    {
        if(document.cookie.indexOf('dev-translator=') !== -1) {
            let placeholders = this.getComponentElement().find('[placeholder]');
            for(let i = 0; i < placeholders.length; i++)
            {
                 let formElement = $(placeholders[i]);
                 let placeholder = formElement.attr('placeholder');
                 placeholder = placeholder.match(/(translated-text=".*?")/)[0];
                 placeholder = placeholder.replaceAll('translated-text=', '');
                 placeholder = placeholder.replaceAll('"', '');
                 formElement.attr('placeholder', placeholder);
            }
        }
    }

    // Construct the class and initialise a scan of the child nodes of the component
    public constructor(componentElement: any, DI: DI) {

        super(componentElement, DI);

        // check if there are elements with validators in the component
        if (this.getFormValidationElements().length) {

            // There are validator present so set the default status of the form to not valid
            this.formValid = false;
        }

        // Setup event listeners
        this.setupFormSubmitEventListener();
        this.setupLastClickedButtonEventListener();
        this.setupFormResetEventListener();
        this.setupRadioGroupEventListener();
        this.setupOnPageShowEventListener();

        // setup any input validators or constraints
        this.addInputValidators();
        this.addInputConstraints();

    }

    /**
     * This maps all un-instantiated validation classes to
     * keys that can be called directly from the validation JSON on the inputs
     */
    protected validatorClassMap(): Object {
        return {
            [RequiredValidator.validatorName]: RequiredValidator,
            [MinLengthValidator.validatorName]: MinLengthValidator,
            [MaxLengthValidator.validatorName]: MaxLengthValidator,
            [EmailValidator.validatorName]: EmailValidator,
            [DummyValidator.validatorName]: DummyValidator,
            [NoSpacesValidator.validatorName]: NoSpacesValidator,
            [NumericOnlyValidator.validatorName]: NumericOnlyValidator,
            [PhoneValidator.validatorName]: PhoneValidator,
            [PostalCodeValidator.validatorName]: PostalCodeValidator,
            [UrlValidator.validatorName]: UrlValidator,
            [NonNumeric.validatorName]: NonNumeric,
            [PasswordMatchValidator.validatorName]: PasswordMatchValidator,
            [EmailMatchValidator.validatorName]: EmailMatchValidator,
            [FileExtensionMatchValidator.validatorName]: FileExtensionMatchValidator,
            [NonCommonPasswordValidator.validatorName]: NonCommonPasswordValidator,
            [CreatePasswordValidator.validatorName]: CreatePasswordValidator,
            [DoesNotMatchValidator.validatorName]: DoesNotMatchValidator,
        };
    }

    /**
     * This maps all un-instantiated input constraint classes to
     * keys that can be called directly from the constraint JSON on the inputs
     */
    public static constraintClassMap(): Object {
        return {
            [PhoneConstraint.constraintName]: PhoneConstraint,
            [NumericConstraint.constraintName]: NumericConstraint,
            [MaxLengthConstraint.constraintName]: MaxLengthConstraint,
            [MaskConstraint.constraintName]: MaskConstraint,
            [AlphaConstraint.constraintName]: AlphaConstraint,
            [NonNumericConstraint.constraintName]: NonNumericConstraint,
            [MoneyConstraint.constraintName]: MoneyConstraint,
        };
    }

    /**
     * A form submission hits here first
     * return true to always submit
     */
    protected async handleFormSubmission(event: any): Promise<void>
    {
        if (this.submitting)
        {
            return new Promise<void>(() => {});
        }

        // Prevent event bubbling
        event.preventDefault();
        // Change form inflight status
        this.submitting = true;
        // manually trigger validation on all forms that require it
        await this.validateInputs();

        // check form doesn't have errors
        if (this.formValid && !this.submitted) {
            this.setButtonToProcessing(true);
            this.submitted = true;
            event.currentTarget.submit();
        }
        // Allow the form to trigger submission again
        this.submitting = false;
    }

    protected addInputConstraints(): this {

        // For each input
        this.getFormConstraintElements().each((key, element) => {

            let inputElement = $(element);

            // Get constraints config from input
            // Get the validation JSON string from the form 'form-validate' attribute
            // This can contain multiple validators and their parameters
            let constraintConfigJson = inputElement.attr('form-constraint');

            // Create an empty object to the rest of the script doesn't
            // shit itself if an invalid JSON string was passed through
            let constraintConfig = {};

            try {
                // Convert JSON string to an Object
                constraintConfig = JSON.parse(constraintConfigJson);
            } catch (e) {
                throw new Error(
                    "Incorrectly formatted JSON string: " + constraintConfigJson
                );
            }

            // For each constraint
            for (let constraintName in constraintConfig) {

                let constraintValue = constraintConfig[constraintName];

                // Get the constraint class
                let constraintClass = AbstractFormComponent.constraintClassMap()[constraintName];

                // Instantiate the constraint class, passing in the element and the constraint value
                let instantiatedConstrant = new constraintClass(inputElement, constraintValue);

                // Apply the constraint
                instantiatedConstrant.apply();
            }
        });

        return this;
    }

    protected addInputValidators(): this {

        // Get all input elements to be validated in this form and set the default
        // form validation state based on whether there are validation elements present
        let inputElements = this.getFormValidationElements();
        let eventTriggers = this.getComponentElement().attr('event-triggers');

        // Loop over all validation enabled elements
        for (let inputElement of inputElements) {
            // Turn each input element into a jQuery object to get access to extra helper methods
            inputElement = $(inputElement);

            let inputId = inputElement.attr('id');
            let type = inputElement.attr('type');
            let tagName = inputElement.prop('tagName');

            let eventTriggerOverride = inputElement.attr('override-trigger');

            //On click for radio
            if(type === 'radio' || type === 'checkbox'){
                this.getComponentElement().on(
                    'click',
                    '#'+inputId,
                    ((element: any)=>{
                        // Check if input is valid
                        this.validateInput(element);
                    }).bind(this, inputElement)
                );
                continue;
            }

            if(tagName === 'SELECT'){
                this.getComponentElement().on(
                    'change',
                    '#'+inputId,
                    ((element: any)=>{
                        this.validateInput(element);
                    }).bind(this, inputElement)
                );
            }

            // On keyup or focusout
            this.getComponentElement().on(
                eventTriggerOverride ?? eventTriggers,
                '#' + inputId,
                ((element: any, event: any) => {
                    // Check if input is valid
                    let inputIsValid = this.validateInput(element, event);

                    if (event.type === 'focusout')
                    {
                        if (!inputIsValid)
                        {
                            this.showFormErrorMessage(inputElement);
                        }
                    }

                }).bind(this, inputElement)
            );
        }

        return this;
    }



    /************************************
     * Validate Input
     ***********************************/

    protected async validateInputs(event: any = null): Promise<boolean> {
        // Get all input elements to be validated in this form and set the default
        // form validation state based on whether there are validation elements present
        let inputElements = this.getFormValidationElements();

        // create & set default value for form errors var
        let formErrors = 0;

        // Loop over all validation enabled elements
        for (let inputElement of inputElements) {
            // Turn each input element into a jQuery object to get access to extra helper methods
            inputElement = $(inputElement);

            // Trigger validate method
            let elementValidationResult = await this.validateInput(inputElement, event);

            if (!elementValidationResult) {
                formErrors++;
            }
        }

        this.showFormErrorMessages();

        // set true of false depending if there are form errors
        this.formValid = !Boolean(formErrors);

        return this.formValid;
    }

    protected async validateInput(inputElement, event = null): Promise<boolean> {

        // Auto validate input if it's hidden
        if (!inputElement.is(':visible')) return true;

        // Get the validation JSON string from the form 'form-validate' attribute
        // This can contain multiple validators and their parameters
        let validateConfigJson = inputElement.attr('form-validate');

        // Create an empty object to the rest of the script doesn't
        // shit itself if an invalid JSON string was passed through
        let validateConfig = {};

        // variable to hold the error count for the specific input
        // as each input can have multiple validators
        let errorCount = 0;

        try {
            // Convert JSON string to an Object
            validateConfig = JSON.parse(validateConfigJson);
        } catch (e) {
            throw new Error(
                "Incorrectly formatted JSON string: " + validateConfigJson
            );
        }

        let validatorResults = {};

        for (let validateFunctionName in validateConfig) {

            if (validateConfig.hasOwnProperty(validateFunctionName)) {

                try {

                    // Get the value from the object
                    let validateFunctionParam = validateConfig[validateFunctionName];

                    // Get the validator class from the map
                    let validatorClass = this.validatorClassMap()[validateFunctionName];

                    let validator = (new validatorClass(inputElement, validateFunctionParam, this.getComponentElement()))
                        .setDI(this.getDI());

                    let validationResult: boolean;

                    if(validator.asyncValidate !== undefined) {
                        validationResult = await validator.asyncValidate(event);
                    }
                    else {
                        validationResult = validator.validate();
                    }

                    if (!validationResult) {
                        // Increment error count
                        errorCount++;
                    }

                    validatorResults[validateFunctionName] = validationResult;
                } catch (e) {
                    throw new Error("No validator exists in map for: " + validateFunctionName);
                }

            }

        }

        let finalValidationResult = !Boolean(errorCount);

        validatorResults['errorCount'] = errorCount;

        // Pass in the result of the validation method along with the element
        // in order to assign the correct element classes
        let inputWrapper = this.getInputWrapper(inputElement);

        if (finalValidationResult)
        {
            if (inputElement.attr('type') === "radio")
            {
                inputElement = this.getComponentElement().find('[name=' + inputElement.attr('name') + ']')
                inputWrapper = this.getInputWrapper(inputElement);
            }

            inputWrapper
                .addClass('input--valid')
                .removeClass('input--invalid');
        }
        else
        {
            inputWrapper
                .addClass('input--invalid')
                .removeClass('input--valid');
        }

        inputElement.attr('valid', finalValidationResult ? 'true':'false');

        this.addErrorMessages(inputElement, validatorResults);

        inputElement.trigger('validated', validatorResults);

        // return true if no errors returned from any of the validator classes
        return finalValidationResult;
    }

    protected getInputValidators(inputElement: any): Array<AbstractValidator>
    {
        // Get the validation JSON string from the form 'form-validate' attribute
        // This can contain multiple validators and their parameters
        let validateConfigJson = inputElement.attr('form-validate');

        // Create an empty object to the rest of the script doesn't
        // shit itself if an invalid JSON string was passed through
        let validateConfig = {};

        try {
            // Convert JSON string to an Object
            validateConfig = JSON.parse(validateConfigJson);
        } catch (e) {
            throw new Error(
                "Incorrectly formatted JSON string: " + validateConfigJson
            );
        }

        let instances = [];


        for (let validateFunctionName in validateConfig) {
            if (validateConfig.hasOwnProperty(validateFunctionName)) {
                try {

                    // Get the value from the object
                    let validateFunctionParam = validateConfig[validateFunctionName];

                    // Get the validator class from the map
                    let validatorClass = this.validatorClassMap()[validateFunctionName];

                    // Pass in the input element and the corresponding
                    // validation parameter and instantiate the class
                    let validationClassInstance = (new validatorClass(inputElement, validateFunctionParam, this.getComponentElement()))
                        .setDI(this.getDI());

                    instances.push(validationClassInstance);

                } catch (e) {
                    throw new Error("No validator exists in map for: " + validateFunctionName);
                }

            }
        }

        return instances;
    }


    /************************************
     * Manage Error Messages
     ***********************************/

    protected addErrorMessages(inputElement: any, elementValidationResult: any): this {
        // if the input we're dealing with is a radio input
        let isRadioInput = inputElement.attr('type') === "radio";
        let validators = [];
        let inputMessages = null;

        if (isRadioInput) {
            let radioGroupName = inputElement.attr('name');
            let errorHolder = this.getComponentElement().find('[form-error=' + radioGroupName + ']');
            inputMessages = errorHolder.children('[message-holder]').html('');
            validators = this.getInputValidators(inputElement);
        } else {
            let inputId = inputElement.attr('id');
            let inputWrapper = this.getInputWrapper(inputElement);
            let inputLabel = inputWrapper.find('[form-error="' + inputId + '"]');
            validators = this.getInputValidators(inputElement);
            inputMessages = inputLabel.find('[message-holder]').html('');
        }

        // Loop over each validator
        for (let validatorIndex in validators) {
            if (validators.hasOwnProperty(validatorIndex)) {
                let validator = validators[validatorIndex];
                let validatorName = validator.constructor.validatorName;

                if (!elementValidationResult[validatorName])
                {
                    inputMessages.append(
                        $('<span>')
                            .addClass('validation-label__message')
                            .attr('form-error-num', validatorIndex)
                            .attr('message', '')
                            .text(validator.getErrorMessage())
                    );
                    break;
                }
            }
        }

        return this;
    }

    protected showFormErrorMessages(timeToShowInSeconds: number = 3000) {
        let errorLabels = this.getFormErrorLabels();

        for (let errorLabel of errorLabels) {
            errorLabel = $(errorLabel);

            if (errorLabel.find('[form-error-num]').length) {
                errorLabel.attr('vis', true);
                setTimeout(() => errorLabel.removeAttr('vis'), timeToShowInSeconds);
            }
        }
    }

    protected showFormErrorMessage(inputElement: any, timeToShowInSeconds: number = 3000) {
        let isRadioInput = inputElement.attr('type') === "radio";
        let errorLabel = this.getFormErrorLabel(isRadioInput ? inputElement.attr('name') : inputElement.attr('id'));
        errorLabel.attr('vis', true);
        setTimeout(() => errorLabel.removeAttr('vis'), timeToShowInSeconds);
    }

    /************************************
     * Event listeners
     ***********************************/

    protected setupOnPageShowEventListener(): this {
        $(window).bind("pageshow", (event) => {
            if (event.originalEvent.persisted) {
                location.reload();
            }
        });

        return this;
    }

    protected setupFormSubmitEventListener(): this {
        // setup event listener for the form submit event
        this.getComponentElement().on(
            'submit',
            this.handleFormSubmission.bind(this)
        );

        return this;
    }

    protected setupLastClickedButtonEventListener(): this {
        this.getComponentElement().on(
            'click',
            '[type=submit], [form-submit]',
            (e) => this.lastClickedButton = $(e.currentTarget)
        );

        return this;
    }

    public setupFormResetEventListener(): this {

        this.getComponentElement().on('reset', () => {

            let formErrorLabels = this.getFormErrorLabels();
            formErrorLabels.removeAttr('vis');
            this.getFormElementWrappers().removeClass('input--invalid input--valid');
            this.getFormValidationElements().removeAttr('valid');
            setTimeout(() => formErrorLabels.find('[message-holder]').html(''), 300);

        });

        return this;
    }

    protected setupRadioGroupEventListener(): this {
        let radios = this.getComponentElement().find('[type="radio"]');
        radios.each((index, radio)=>{
            radio = $(radio);
            let labelHolder = radio.parents('label');
            let radioName = radio.attr('name');
            let validationLabel = this.getComponentElement().find('[form-error="' + radioName + '"]');
            labelHolder.hover(()=>{
                let hasMessage:boolean = validationLabel.find('[message="true"]');
                if (hasMessage) {
                    validationLabel.attr('vis', true);
                }
            }, ()=>{
                validationLabel.removeAttr('vis');
            })
        });
        return this;
    }

    /************************************
     * Methods to handle form buttons
     ***********************************/

    protected getClickedButton() {
        let clickedButton = this.getSubmitButtons().filter(':focus');

        if (!clickedButton.length) {
            clickedButton = this.lastClickedButton;
        }

        return clickedButton;
    }

    protected getSubmitButtons() {
        return this.getComponentElement().find('[type="submit"], [form-submit]');
    }

    protected setButtonToProcessing(setButtonToProcessing: boolean = true, button: any = null, disableButton: boolean = true): this {
        let submitButton: any = this.getSubmitButtons();

        if (setButtonToProcessing) {
            // If a button element has been passed in use that
            if (button) {
                submitButton = button;
            }

            // If there are multiple submit buttons
            // run a method to get the one that was clicked
            if (submitButton.length > 1) {
                submitButton = this.getClickedButton();
            }

            // If no submit is found try get the
            // submit button from document.activeElement
            if (submitButton.length == 0) {
                submitButton = $(document.activeElement).filter("[type=submit], [form-submit]");
            }
        } else {
            // Find the submit button that is currently set to processing
            submitButton = submitButton.filter('[processing]');
        }

        // if the button has the attribute "no-process" don't update to processing
        if (submitButton.attr("no-process") !== undefined) {
            return this;
        }

        // save button data if nothing stored in property
        if (!this.buttonData) {
            let loadingContent = this.getComponentElement().attr('loading-content');
            let original = '';
            loadingContent = JSON.parse(loadingContent).content;

            if (submitButton.find('[text]').length !== 0) {
                original = submitButton.find('[text]').html();
            } else {
                original = submitButton.html();
            }

            this.buttonData = {
                "original": original,
                "processing": loadingContent
            };
        }

        if (setButtonToProcessing) {
            // Disable and swap out button text
            if (submitButton.find('[text]').length !== 0) {
                submitButton.find('[text]').html(this.buttonData.processing);
            } else {
                submitButton.html(this.buttonData.processing);
            }
            submitButton.attr('processing', true);

            if (disableButton) {
                submitButton.prop("disabled", true);
            }

            this.getComponentElement().find(':input').attr('readonly', true);

        } else {
            // Enable the the form and swap out button text for the original
            if (submitButton.find('[text]').length !== 0) {
                submitButton.find('[text]').html(this.buttonData.original);
            } else {
                submitButton.html(this.buttonData.original);
            }
            submitButton.removeAttr('processing');
            submitButton.prop("disabled", false);
            this.getComponentElement().find(':input').removeAttr('readonly');
        }

        return this;
    }

    protected getProcessingText(button: any = null): string {
        let submitButton: any = (button) ? button : this.getSubmitButtons();

        if (submitButton.length > 0) {
            let processingMessage = submitButton.first().attr('processing-message');

            if (typeof processingMessage !== "undefined") {
                return processingMessage;
            }
        }

        // Get translated processing text
        let configProcessingText = this.getDI().getConfig().getByPath('FormComponent:processingText');

        if (configProcessingText !== null && typeof configProcessingText == "string") {
            this.processingText = configProcessingText;
        }

        return this.processingText;
    }


    /************************************
     * Methods to get wrapper elements,
     * input & error labels
     ***********************************/

    protected getFormValidationElements(): any {
        // Get all input elements to be validated in this form
        return $('[form-validate]', this.getComponentElement());
    }

    protected getFormConstraintElements() {
        return $('[form-constraint]', this.getComponentElement());
    }

    protected getFormErrorLabels() {
        return this.getComponentElement().find('[form-error]');
    }

    protected getFormErrorLabel(id: string) {
        return this.getComponentElement().find('[form-error="' + id + '"]');
    }

    protected getFormElementWrappers() {
        return $('[wrapper]', this.getComponentElement());
    }

    protected getInputWrapper(inputElement){
        if (inputElement.attr('wrapper') !== undefined) {
            return inputElement;
        }

        return inputElement.parents('[wrapper]');
    }
}
