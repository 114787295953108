import {AbstractConstraint} from "../AbstractConstraint";
import {ConstraintInterface} from "../ConstraintInterface";

export class AlphaConstraint extends AbstractConstraint implements ConstraintInterface
{
	public static constraintName = "alpha";
	// Allows only alphabetical characters
	protected constraintRegex: RegExp = /^[a-zA-Z\ ]*$/;
	protected constraintReplaceRegex: RegExp = /[^a-zA-Z\ ]/;
}
