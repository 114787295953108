import {AbstractValidator} from "../AbstractValidator";

declare let $: any;

export class CreatePasswordValidator extends AbstractValidator {

    public static validatorName: string = "createPassword";

    public validate(): boolean {
        return false;
    }

    public async asyncValidate(event: Event): Promise<boolean>
    {
        let minResult, commonPasswordResult = null;

        let fieldNameAttribute = this.inputElement.attr('name');
        let passwordElement = this.formComponent.find('[name="' + fieldNameAttribute + '"]');

        let commonValidation = passwordElement.parents('form').find('[common-password-validation]')
        let minValidation = passwordElement.parents('form').find('[min-length-validation]')

        minResult = this.minLengthValidation();

        if (event.type == 'submit')
        {
            if (minResult)
            {
                commonPasswordResult = await this.commonPasswordValidation(commonValidation);

                minValidation.addClass('password-criteria__item--valid')

                if (commonPasswordResult)
                {
                    commonValidation.addClass('password-criteria__item--valid')
                }
                else
                {
                    commonValidation.removeClass('password-criteria__item--valid')
                }
            }
            else
            {
                minValidation.removeClass('password-criteria__item--valid')
            }
        }

        if (event.type == 'keyup')
        {
            if (minResult)
            {
                minValidation.addClass('password-criteria__item--valid')
            }
            else
            {
                minValidation.removeClass('password-criteria__item--valid')
                commonValidation.removeClass('password-criteria__item--valid')
            }
        }

        if (event.type == 'focusout' && minResult)
        {
            commonPasswordResult = await this.commonPasswordValidation(commonValidation);

            if (commonPasswordResult)
            {
                commonValidation.addClass('password-criteria__item--valid')
            }
            else
            {
                commonValidation.removeClass('password-criteria__item--valid')
            }
        }

        return (minResult && commonPasswordResult);
    }

    public minLengthValidation(): boolean
    {
        let inputElement = this.inputElement;
        let inputValue = inputElement.val();
        let inputLength = inputValue.length;

        return inputLength >= 6;
    }

    public async commonPasswordValidation(validationElement)
    {
        let inputElement = this.inputElement;
        let inputValue = inputElement.val();
        let formData = new FormData();
        formData.append('password', inputValue);
        let endPoint = inputElement.parents('[non-common-password-endpoint]').attr('non-common-password-endpoint');

        validationElement.addClass('password-criteria__item--loading')

        let result = await fetch(endPoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
        })
            .then((response) => response.json())
            .then((response) => response.isValid)
            .catch(() => false);

        validationElement.removeClass('password-criteria__item--loading')

        return await result;
    }

}