
import {AbstractConstraint} from "../AbstractConstraint";
import {ConstraintInterface} from "../ConstraintInterface";

export class MaxLengthConstraint extends AbstractConstraint implements ConstraintInterface
{
	public static constraintName = "maxLength";

	public getConstraintRegex()
	{
		return new RegExp('^.{0,' + this.constraintParam + '}$');
	}
 
	protected getConstraintReplaceRegex(): RegExp
	{
		return new RegExp('(?<!^.{0,' + this.constraintParam + '}).', 'g');
	}

}
