import {AbstractConstraint} from "../AbstractConstraint";
import {ConstraintInterface} from "../ConstraintInterface";

declare let $:any;

export class MaskConstraint extends AbstractConstraint implements ConstraintInterface
{
    public static constraintName = "mask";
    protected constraintRegex = null;
    private selectionStart: number;
    private selectionEnd: number;
    private mask: string;

    public apply(): this
    {
        let inputElement = this.inputElement;
        let inputValue;
        this.mask = this.constraintParam;

        //AUTOFILL & PASTE EVENT
        inputElement.on('change input paste', (e)=>{
            if(e.type === 'paste')
            {
                e.preventDefault();
                e.stopPropagation();
                inputValue = inputElement.val();
                let pastedValue = e.originalEvent.clipboardData.getData('text');
                if(!pastedValue){
                    return;
                }
                inputValue += pastedValue;
            }else{
                inputValue = inputElement.val();
            }
            inputValue = inputValue.replace(/\s+/g, '');
            inputValue  = this.removeInputMaskPlaceholders(inputValue);
            let mask = this.removeInputMaskPlaceholders(this.mask);

            if(inputValue.length > mask.length)
            {
                inputValue = inputValue.substring(0, mask.length);
            }

            mask = mask.split('');
            inputValue = inputValue.split('');

            let trim = inputValue.length;

            for(let i = 0; i < mask.length; i++){
                let maskChar = mask[i];
                let regex = this.convertMaskToRegex(maskChar);
                let char = inputValue[i];

                if(!char){
                    break;
                }

                if(!regex.test(char))
                {
                    if(this.shouldMatchCharCase(maskChar, char))
                    {
                        inputValue[i] = this.matchCharCaseToMask(maskChar, char);
                    }
                    else{
                        trim = i;
                        break;
                    }
                }
            }

            inputValue = inputValue.slice(0, trim);

            //INSERT PLACEHOLDERS
            if(inputValue.length>0){
                inputValue = this.insertPlaceholders(inputValue);
            }

            inputElement.val(inputValue.join(''));
        });

        //INSERT & DELETE EVENT
        inputElement.on('keydown', (e)=>{
            inputValue = inputElement.val();

            this.selectionStart = e.target.selectionStart;
            this.selectionEnd = e.target.selectionEnd;
            let selection = (this.selectionEnd - this.selectionStart)>0;
            let keyCode = e.keyCode;
            let midSelection = this.selectionStart !== inputValue.length;

            if(e.metaKey || e.ctrlKey)
            {
                return;
            }

            //DELETE EVENT LISTENER
            if(keyCode === 8)
            {

                if(this.selectionStart === 0 && this.selectionEnd === 0)
                {
                    return;
                }

                e.preventDefault();

                if(selection)
                {
                    inputValue = this.removeSelection(inputValue, this.selectionStart, this.selectionEnd);
                    this.selectionEnd = this.selectionStart;
                }
                else{   //1 char selection
                    inputValue = this.removeCharAt(inputValue, this.selectionStart);
                    this.selectionStart--;
                    this.selectionEnd--;
                }

                if(inputValue === ''){
                    inputElement.val(inputValue);
                    return;
                }

                let prevCharInMask = this.mask.charAt(this.selectionStart-1);

                while(!this.isAlphaNumericPlaceholder(prevCharInMask) && prevCharInMask!=='')
                {
                    inputValue = this.removeCharAt(inputValue, this.selectionStart);
                    this.selectionStart--;
                    this.selectionEnd--;
                    prevCharInMask = this.mask.charAt(this.selectionStart-1);
                }

                inputElement.val(inputValue);

                this.restoreCursorPosition(e);

                if(midSelection)
                {
                    let partialRegex = this.convertMaskToRegex(
                        this.mask.slice(0, inputValue.length)
                    );

                    if(!partialRegex.test(inputValue))
                    {
                        inputValue = this.reapplyInputMask(inputValue);
                        inputElement.val(inputValue);
                        this.restoreCursorPosition(e);
                    }
                }
            }

            //INSERT EVENT LISTENER
            else if((48 <= keyCode && keyCode <= 90))
            {
                e.preventDefault();
                e.stopPropagation();
  
                let pressedCharacter = this.getCharacter(e);

                if(!pressedCharacter)
                {
                    return;
                }

                if(selection)
                {
                    inputValue = this.removeSelection(inputValue, this.selectionStart, this.selectionEnd);
                    this.selectionEnd = this.selectionStart;
                }

                let nextCharInMask = this.mask.charAt(this.selectionEnd);

                if(this.shouldMatchCharCase(nextCharInMask, pressedCharacter))
                {
                  pressedCharacter = this.matchCharCaseToMask(nextCharInMask, pressedCharacter);
                }

                //End of mask no more input
                if(nextCharInMask === ''){
                    return;
                }

                while(!this.isAlphaNumericPlaceholder(nextCharInMask))
                {
                    inputValue = this.addCharAt(inputValue, nextCharInMask, this.selectionEnd);
                    this.selectionEnd++;
                    nextCharInMask = this.mask.charAt(this.selectionEnd);

                    if(this.shouldMatchCharCase(nextCharInMask, pressedCharacter))
                    {
                      pressedCharacter = this.matchCharCaseToMask(nextCharInMask, pressedCharacter);
                    }
                }

                inputValue = this.addCharAt(inputValue, pressedCharacter, this.selectionEnd);
                this.selectionEnd++;

                let partialRegex = this.convertMaskToRegex(this.mask.slice(0, inputValue.length));

                if(partialRegex.test(inputValue))
                {
                    inputElement.val(inputValue);
                    this.selectionStart = this.selectionEnd;
                    this.restoreCursorPosition(e);
                }
                else
                {
                    if(midSelection)
                    {
                        inputValue = this.reapplyInputMask(inputValue);
                        partialRegex = this.convertMaskToRegex(this.mask.slice(0, inputValue.length));
                        if(partialRegex.test(inputValue) && !(inputValue.length > this.mask.length))
                        {
                            inputElement.val(inputValue);
                            this.selectionStart = this.selectionEnd;
                            this.restoreCursorPosition(e);
                        }
                    }
                }
            }
        });

        return this;
    }

    private insertPlaceholders(splitInputValue: Array<string>): Array<string>
    {
        let placeholders = this.getInputMaskPlaceholdersWithPositions();

        for(let position in placeholders)
        {
            let pos = +position;
            if(placeholders.hasOwnProperty(pos) && !(pos >= splitInputValue.length)){
                splitInputValue.splice(pos, 0, placeholders[pos]);
            }
        }

        return splitInputValue;
    }

    private convertMaskToRegex(mask: string): RegExp
    {
        return new RegExp('^'+
            mask
            .replace(/x/g, '[a-z]')
            .replace(/X/g, '[A-Z]')
            .replace(/#/g, '[0-9]')
            +'$', 'g');
    }

    private isAlphaNumericPlaceholder(char: string): boolean
    {
        return (char === 'x' || char === 'X' || char === '#');
    }

    private isAlphaPlaceholder(char: string): boolean
    {
        return (char === 'x' || char === 'X');
    }

    private isLetter(char: string): boolean
    {
        return !!char.match(/[a-z]/);
    }

    private isCapitalLetter(char: string): boolean
    {
        return !!char.match(/[A-Z]/);
    }

    private addCharAt(str, char:string, pos:number)
    {
        str = str.split('');
        str.splice(pos, 0, char);
        return str.join('');
    }

    private removeCharAt(str, pos: number)
    {
        str = str.split('');
        str.splice(pos - 1, 1)
        return str.join('');
    }

    private removeSelection(str, start: number, end: number)
    {
        str = str.split('');
        str.splice(start, end-start);
        return str.join('');
    }

    private restoreCursorPosition(event)
    {
        event.target.selectionStart = this.selectionStart;
        event.target.selectionEnd = this.selectionEnd;
        return event;
    }

    private reapplyInputMask(value)
    {
        value = this.removeInputMaskPlaceholders(value);
        value = this.applyInputMask(value);
        return value;
    }

    private applyInputMask(value)
    {
        value = value.split('');
        let mask = this.mask.split('');

        for(let i = 0; i<mask.length; i++)
        {
            let placeholder = mask[i];
            let char = value[i];
            if(char){
                let regex = this.convertMaskToRegex(placeholder);
                if(!regex.test(char))
                {
                    if(this.isAlphaNumericPlaceholder(mask[i]))
                    {
                        value.splice(i, 1);
                        i--;
                    }else{
                        value.splice(i, 0, mask[i]);
                    }
                }
            }else{
                break;
            }
        }

        return value.join('');
    }

    private removeInputMaskPlaceholders(value)
    {
        this.getInputMaskPlaceholders().forEach((placeholder)=>{
            value = value.replace(new RegExp(placeholder, 'g'), '');
        });

        return value;
    }

    private getInputMaskPlaceholdersWithPositions(): Object
    {
        let mask = this.mask.split('');
        let positions = {};

        mask.forEach((value, index)=>{
            if(value !== 'x' && value !== 'X' && value !== '#')
            {
                positions[index] = value;
            }
        });

        return positions;
    }

    private getInputMaskPlaceholders(): Array<string>
    {
        return this.mask
            .replace(/x/g, '')
            .replace(/X/g, '')
            .replace(/#/g, '')
            .split('');
    }

    private shouldMatchCharCase(maskChar:string, char:string): boolean
    {
        return this.isAlphaPlaceholder(maskChar) && (this.isLetter(char) || this.isCapitalLetter(char));
    }

    private matchCharCaseToMask(maskChar: string, char: string)
    {
        if(this.isCapitalLetter(maskChar))
        {
            return char.toUpperCase();
        }else{
            return char.toLowerCase();
        }
    }
}
