import {DI} from "../../../../../_resources/ts/DI/DI";

declare let $: any;

export abstract class AbstractConstraint
{
	protected inputElement: any = null;
	protected constraintParam: any = null;
	protected constraintRegex: RegExp = /.+/;
	protected constraintReplaceRegex: RegExp = /.+/;
	protected inputLength: number = 0;
	protected inputType: string = null;

	public constructor(inputElement, constraintParam?)
	{
		this.inputElement = inputElement;
		this.constraintParam = constraintParam;
	}

	public getConstraintRegex(): RegExp
	{
		return this.constraintRegex;
	}
	
	protected getConstraintReplaceRegex(): RegExp
	{
		return this.constraintReplaceRegex;
	}

	public apply(): this
	{
		let inputElement = this.inputElement;
		let di = new DI();

		inputElement.on('focus', (e) => {
			if (!this.inputType)
			{
				let inputType = inputElement.get(0).type;
				
				if (inputType === 'email')
				{
					this.inputType = inputType;
					inputElement.get(0).type = 'text';
				}
			}
		});

		if (di.getUserAgentModule().isAndroid())
		{
			// On keypress
			inputElement.on('keyup', (e) =>
			{
				// Get the current value of the input (before this new character has been added)
				let currentInputValue = this.inputElement.val();

				// If the new value won't pass the regex
				if (!this.getConstraintRegex().test(currentInputValue))
				{
					this.inputElement.val(currentInputValue.replace(this.getConstraintReplaceRegex(), ''));
				}

				return this;
			});
		}
		else
		{
			// On keypress
			inputElement.on('keypress paste', (e) =>
			{
				if (e.type === "paste")
				{
					e.stopPropagation();

					let inputValue = inputElement.val();
					let pastedValue = e.originalEvent.clipboardData.getData('text');
			
					if (!pastedValue)
					{
						return;
					}
					//add paste in selection
					inputValue = inputValue.substring(0, e.selectionStart) + pastedValue + inputValue.substring(e.selectionEnd, inputValue.length);

					if (!this.getConstraintRegex().test(inputValue))
					{
						// Prevent it from being added
						e.preventDefault();
					}
				}
				else
				{
					let currentInputValue = this.generateNewText(
						this.inputElement.val(),
						String.fromCharCode(this.getCharCode(e)),
						e.selectionStart,
						e.selectionEnd
					);
					
					// If the new value won't pass the regex
					if (!this.constraintRegex.test(currentInputValue))
					{
						// Prevent it from being added
						e.preventDefault();
						e.stopPropagation();
					}
				}
			});
		}
		
		inputElement.on('blur', (e) => {
			if (this.inputType)
			{
				e.currentTarget.type = this.inputType;
				this.inputType = null;
			}
		});
		
		return this;
	}

	protected generateNewText(inputValue, newCharacter, selectionStart, selectionEnd)
	{
		let stringParts = inputValue.split('');
		let selectionLength = selectionEnd - selectionStart;

		stringParts.splice(selectionStart, selectionLength, newCharacter);

		return stringParts.join('');
	}
	
	protected getCharCode(event)
	{
		let charCode = null;

		if(event.keyCode)
		{
			charCode = event.keyCode;
		}
		// Netscape/Firefox/Opera
		else if(event.which)
		{
			charCode = event.which;
		}

		return charCode;
	}
	
	protected getCharacter(e)
	{
		let $element = $(e.currentTarget);
		let value: string = $element.val().toString();
		let stringLength = value.length;
		let caretPosition = $element.get(0).selectionStart;
		let offset = ((stringLength - caretPosition) + 1) * -1;
		return value.substr(offset, 1);
	}

	protected getInputValue(): string
	{
		if (this.inputElement.attr('value'))
		{
			return this.inputElement.attr('value');
		}
		else
		{
			return this.inputElement.val();
		}
	}

	protected setInputValue(value: string)
	{
		if (this.inputElement.attr('value'))
		{
			return this.inputElement.attr('value', value);
		}
		else
		{
			return this.inputElement.val(value);
		}
	}
}
