import {AbstractConstraint} from "../AbstractConstraint";
import {ConstraintInterface} from "../ConstraintInterface";

export class NonNumericConstraint extends AbstractConstraint implements ConstraintInterface
{
	public static constraintName = "nonNumeric";
	// Allows no numbers, no exceptions
	protected constraintRegex: RegExp = /^[^0-9]*$/;
	protected constraintReplaceRegex: RegExp = /[0-9]/g;
}
