
import {AbstractConstraint} from "../AbstractConstraint";
import {ConstraintInterface} from "../ConstraintInterface";

export class NumericConstraint extends AbstractConstraint implements ConstraintInterface
{
	public static constraintName = "numeric";
	// Allows numbers only, no exceptions
	protected constraintRegex: RegExp = /^[0-9]+$/;
	protected constraintReplaceRegex: RegExp = /[^0-9]/;
}