import {DI} from "./_resources/ts/DI/DI";
import {DIAware} from "./_resources/ts/DI/DIAware";

declare let $: any;

export abstract class AbstractComponent extends DIAware
{
  public static selector: string = null;
  protected componentElement: any;
  protected componentParam: any;

  public constructor(componentElement: any, DI: DI)
  {
    super();
    this.componentElement = componentElement;
    this.setDI(DI);
    return this;
  }

  public runOnce() {}
  public init() {}
  public afterInit() {}

  public getComponentClassName()
  {
    return this.constructor['name'];
  }

  public getComponentParameter()
  {
    this.componentParam = this
        .componentElement
        .attr(this.constructor['selector']);

    return this.componentParam;
  }

  public getComponentElement()
  {
    return $(`[component-id="${this.getId()}"]`, document);
  }

  public getId(): string
  {
    return this.componentElement.attr('component-id');
  }

  public getUrlParam(paramName : string)
  {
    let results = new RegExp('[\?&]' + paramName + '=([^&#]*)').exec(window.location.href);
    if (results == null)
    {
      return null;
    }
    else
    {
      return results[1] || 0;
    }
  }

  /**
   * @param func
   * @param wait
   */
  public debounce = (func: () => any, wait: number = 25) => {
    let timeout;
    return () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func();
      }, wait);
    };
  };

}

export class ComponentLoader {
  constructor(component) {
    let elements = $(`[${component.selector}]`);
    for (let key = 0; key < elements.length; key++) {
      if (elements.hasOwnProperty(key))
      {
        let element = $(elements[key]);
        let id = component.selector + '_' + key;
        element.attr('component-id', id);
        let initComponent = (new component(element, new DI()));
        if (key === 0) initComponent.runOnce();
        initComponent.init();
      }
    }
  };
}
