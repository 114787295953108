import {AbstractValidator} from "../AbstractValidator";

export class PhoneValidator extends AbstractValidator {

    public static validatorName: string = "phone";

    public validate(): boolean {

        let placeholder = this.inputElement.attr('placeholder');
        let value = this.getInputValue();

        // Return true, if required is set as a validator and value is false
        let validators = this.inputElement.attr('form-validate');

        if(typeof validators !== "undefined"){
            validators = JSON.parse(validators);
            if (typeof validators.required !== "undefined") {
                if (validators.required === false) {
                    // If placeholder exists
                    if (placeholder) {
                        // If value is the same as placeholder
                        if (value == placeholder) {
                            return true;
                        }
                    }
                }
            }
        }


        // removes any spaces they may have entered
        let inputValue = value.replace(/\s/g, '');
        let inputLength = inputValue.length;


        // Full regex pattern: ^([\+]?)([^\D]+)$
        let regexSegments = {
            "beginning-of-line": '^',
            "optional-plus-character": '([\\+]?)',
            "greedy-match-numbers-only": '([^\\D]+)',
            "end-of-line": '$',
        };

        //Build phone number regex pattern
        let regEx = new RegExp(
            regexSegments["beginning-of-line"] +
            regexSegments["optional-plus-character"] +
            regexSegments["greedy-match-numbers-only"] +
            regexSegments["end-of-line"]
        );

        // If there is no phone number present pass validation
        let phoneNumberPresent = (inputLength === 0);
        if (phoneNumberPresent) return true;

        // check the number of characters or between 6 - 15
        let validPhoneNumberLength = (inputLength <= 20 && inputLength >= 6);

        // check phone number matches regex
        let phoneNumberMatchRegex = regEx.test(inputValue);

        // if they are both true, return true
        return (validPhoneNumberLength && phoneNumberMatchRegex);
    }
}
