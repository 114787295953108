
import {AbstractValidator} from "../AbstractValidator";

export class UrlValidator extends AbstractValidator
{
	public static validatorName: string = "url";

	public validate(): boolean {
		let inputValue = this.getInputValue();
		let inputLength = inputValue.length;

		if (!inputLength) return true;

		let urlRegex = new RegExp(/^(?:(http|https):\/\/)?(?:[A-Za-z0-9\-]+)?\.([A-Za-z0-9\-]+)(?:\.[A-Za-z0-9]+)*(?:\/[A-Za-z0-9\-\/\_\\?\&\=\[\]\"\'\(\)\.]+)?$/, "i");

		return (urlRegex.test(inputValue));
	}
}
