import {AbstractValidator} from "../AbstractValidator";

declare let $: any;

export class FileExtensionMatchValidator extends AbstractValidator {

	public static validatorName: string = "fileExtensionMatch";

	public validate(): boolean
	{
		let filePath = this.inputElement.val();
		let fileExt = filePath.substring(filePath.lastIndexOf('.') + 1, filePath.length);
		return fileExt == this.validateParam
	}
}
