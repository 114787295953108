import {AbstractModule} from "../AbstractModule";

export class GenerateModule extends AbstractModule {

	public hashFromString(string: string): string|number
	{
		let hash = 0;

		if (string.length == 0)
		{
			return hash;
		}

		for (let i = 0; i < string.length; i++) {
			let char = string.charCodeAt(i);
			hash = ((hash << 5) - hash) + char;
			hash = hash & hash; // Convert to 32bit integer
		}

		return hash;
	}
}
