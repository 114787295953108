import {DIAware} from "../DIAware";
import {DI} from "../DI";

export abstract class AbstractModule extends DIAware
{
	public constructor(DI: DI)
	{
		super();
		this.setDI(DI);
	}
}
