
import {AbstractValidator} from "../AbstractValidator";

export class NoSpacesValidator extends AbstractValidator {

	public static validatorName: string = "noSpaces";

	public validate(): boolean {

		// removes any spaces they may have entered
		let inputValue = this.getInputValue();
		let inputLength = inputValue.length;

		// Full regex pattern: ^[^\ ]+$
		let regexSegments = {
			"beginning-of-line": '^',
			"all-characters-apart-from-spaces": '[^\\ ]+',
			"end-of-line": '$',
		};

		// Build numeric only regex pattern
		let regEx = new RegExp(
			regexSegments["beginning-of-line"] +
			regexSegments["all-characters-apart-from-spaces"] +
			regexSegments["end-of-line"]
		);

		// If there is no input value present pass validation
		let valuePresent = (inputLength === 0);
		if (valuePresent) return true;

		// Return true if regex is matched
		return regEx.test(inputValue);
	}
}
