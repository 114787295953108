
import {ValidatorInterface} from "./ValidatorInterface";
import {DIAware} from "../../../../../_resources/ts/DI/DIAware";

export abstract class AbstractValidator extends DIAware implements ValidatorInterface
{
	public validatorName: string;
	protected inputElement: any = null;
	protected formComponent: any = null;
	protected validateParam: string = null;

	public constructor (inputElement, validateParam, formComponent)
	{
		super();
		this.inputElement = inputElement;
		this.validateParam = validateParam;
		this.formComponent = formComponent;
		return this;
	}

	public validate(): boolean
	{
		throw new Error(
			"You need to implement the validate() method on the (" + this.constructor['name'] + ") class!"
		);
	}

	public getFunctionName(fn)
	{
		let f = typeof fn == 'function';
		let s = f && ((fn.name && ['', fn.name]) || fn.toString().match(/function ([^\(]+)/));
		return (!f && 'not a function') || (s && s[1] || 'anonymous');
	}

	public getErrorMessage(): string
	{
		let validatorName = this.constructor['validatorName'];
		return JSON.parse(this.inputElement.attr('form-validation-messages'))[validatorName];
	}

	protected getInputValue(): string
	{
		if(this.inputElement.attr('value'))
		{
			return this.inputElement.attr('value');
		}
		else
		{
			return this.inputElement.val();
		}
	}
}
