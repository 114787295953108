import {AbstractModule} from "../AbstractModule";
import {DI} from "ui-base/src/_resources/ts/DI/DI";

declare let $: any;

export class UrlModule extends AbstractModule {

    urlValues: Array<string> = [];

    constructor(DI: DI) {
        super(DI);

        $(window).on('load', () => {
            this.removeUrlGetParam('exit');
        });
    }

    getUrl(): string
    {
        return window.location.href;
    }

    getUrlHashValue(): string|null
    {
        if(window.location.href.indexOf('#') !== -1)
        {
            return window.location.href.slice(window.location.href.indexOf('#') + 1);
        }

        return null;
    }

    getUrlParamArray(): Array<string>
    {
        if(this.urlValues.length === 0)
        {
            let values = [];
            let parameter;

            let parameters = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');
            for (let i = 0; i < parameters.length; i++) {
                parameter = parameters[i].split('=');
                values[parameter[0]] = parameter[1];
            }

            this.urlValues = values;
        }

        return this.urlValues;
    }

    getUrlParamByKey(keyValue: string): string
    {
        let parameters = this.getUrlParamArray();
        if (keyValue in parameters)
        {
            return parameters[keyValue];
        }

        console.info(keyValue + ' is not present in the Parameters Array!');
    }

    /**
     * @param key: string
     * @param url: string
     */
    removeUrlGetParam(key: string, url: string = null): string {
        let sourceURL = url !== null ? url : this.getUrl();
        let rtn = sourceURL.split("?")[0], param, params_arr = [];
        let queryString = (sourceURL.indexOf("?") !== -1) ? sourceURL.split("?")[1] : "";

        if (queryString !== "") {
            params_arr = queryString.split("&");
            for (let i = params_arr.length - 1; i >= 0; i -= 1) {
                param = params_arr[i].split("=")[0];
                if (param === key) {
                    params_arr.splice(i, 1);
                }
            }
            rtn = rtn + "?" + params_arr.join("&");
        }
        return rtn;
    }

    removeAllUrlGetParams(): void {
        let uri = window.location.toString();
        if (uri.indexOf("?") > 0) {
            let cleanUri = uri.substring(0, uri.indexOf("?"));
            window.history.replaceState({}, document.title, cleanUri);
        }
    }

    onHashChange(callback: (hash: string) => void) {
        $(document).on('custom-hash-change', (e, hash) => {
            callback(hash);
        });
        return this;
    }

    goBackPage(page: number = -1) {
        window.history.go(page);
        return this
    }

}
