
export class Environment
{
	private env: string;

	public constructor(env: string)
	{
		this.env = env;
	}

	public getEnv(): string
	{
		return this.env;
	}

	public isDev(): boolean
	{
		return (this.env == 'dev');
	}

	public isQa(): boolean
	{
		return (this.env == 'qa');
	}

	public isUat(): boolean
	{
		return (this.env == 'uat');
	}

	public isProd(): boolean
	{
		return (this.env == 'prod');
	}
}
