import {AbstractValidator} from "../AbstractValidator";

export class PostalCodeValidator extends AbstractValidator {

    public static validatorName: string = "postalCode";
    private inputValue: string;

    public validate(): boolean {
        // removes any spaces they may have entered
        this.inputValue = this.getInputValue().replace(/\s/g, '');
        let inputLength = this.inputValue.length;

        // If nothing entered pass validation
        if (!inputLength) return true;

        // Very loose match on postal codes as we cover many countries
        return (inputLength >= 3 && inputLength <= 10);
    }

    private isValidUk() {
        // regex string to check for UK Post code
        // Full regex pattern: ^(\+?)([^\D]+)$
        let regexSegments = {
            "beginning-of-line": '^',
            "match-one-to-two-alpha-chars": '[A-Z]{1,2}',
            "match-one-numeric-char": '[0-9]',
            "optional-match-alphanumeric-char": '[0-9A-Z]?',
            "match-numeric-char": '[0-9]',
            "match-two-alpha-chars": '[A-Z]{2}',
            "end-of-line": '$',
        };

        // Build phone number regex pattern
        let regEx = new RegExp(
            regexSegments["beginning-of-line"] +
            regexSegments["match-one-to-two-alpha-chars"] +
            regexSegments["match-one-numeric-char"] +
            regexSegments["optional-match-alphanumeric-char"] +
            regexSegments["match-numeric-char"] +
            regexSegments["match-two-alpha-chars"] +
            regexSegments["end-of-line"],
            'gi' // global match case insensitive
        );

        // Check if matches UK postcode
        return regEx.test(this.inputValue);
    }

    private isValidUs() {
        // regex string to check for US ZIP code
        // Full regex pattern: ^\d{5}$
        let regexSegments = {
            "beginning-of-line": '^',
            "match-five-numeric-chars": '\d{5}',
            "end-of-line": '$',
        };

        // Build phone number regex pattern
        let regEx = new RegExp(
            regexSegments["beginning-of-line"] +
            regexSegments["match-five-numeric-chars"] +
            regexSegments["end-of-line"],
            'gi' // global match case insensitive
        );

        // Check if matches UK postcode
        return regEx.test(this.inputValue);
    }
}
