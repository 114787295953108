import {UAParser} from "ua-parser-js/src/ua-parser.js";

export class UserAgentModule
{
	private browserName: string;
	private browserMajorVersion: number;
	private osName: string;
	private osVersion: string;
	private deviceType: string;

	public constructor()
	{
		let userAgentParserObject = new UAParser(navigator.userAgent).getResult();
		this.deviceType = userAgentParserObject['device']['type'];
		this.browserName = userAgentParserObject['browser']['name'];
		this.browserMajorVersion = parseInt(userAgentParserObject['browser']['major']);
		this.osName = userAgentParserObject['os']['name'];
		this.osVersion = userAgentParserObject['os']['version'];
	}

	public getBrowserName(): string
	{
		return this.browserName;
	}

	public getMajorBrowserVersion(): number
	{
		return this.browserMajorVersion;
	}

	public getOsName(): string
	{
		return this.osName;
	}

	public getOsVersion(): string
	{
		return this.osVersion;
	}

	public isChrome(): boolean
	{
		// Created an extra check for Edge browser as it's getting a false positive
		let regex = new RegExp('^.*(Chrome|chrome)(?!.*(Edge|edge|edg)).*$', 'g');
		if (regex.test(navigator.userAgent))
		{
			regex = new RegExp('Chrome|chrome');
			return regex.test(this.browserName);
		}
		return false;
	}

	public isEdge(): boolean
	{
		// Created an extra check for Edge browser as it's getting a false positive
		let regex = new RegExp('^.*(Edge|edge).*$', 'g');
		return regex.test(navigator.userAgent);
	}

	public isChromiumEdge(): boolean
	{
		// Created an extra check for the new Chromium Edge browser
		let regex = new RegExp('^.*(Edg|edg).*$', 'g');
		return regex.test(navigator.userAgent);
	}

	public isFirefox(): boolean
	{
		let regex = new RegExp('Firefox|firefox');
		return regex.test(this.browserName);
	}

	public isOpera(): boolean
	{
		let regex = new RegExp('Opera|opera');
		return regex.test(this.browserName);
	}

	public isSafari(): boolean
	{
		let regex = new RegExp('^((?!chrome|android).)*safari\/', 'ig');
		return regex.test(navigator.userAgent);
	}

	public isInternetExplorer(): boolean
	{
		return navigator.userAgent.indexOf("MSIE ") > -1 || navigator.userAgent.indexOf("Trident/") > -1
	}

	public isMac(): boolean
	{
		let regex = new RegExp('Mac OS');
		return regex.test(this.osName);
	}

	public isMobile(): boolean
	{
		return this.deviceType === "mobile";
	}

	public isTablet(): boolean
	{
		return this.deviceType === "tablet";
	}

	public isIphone(): boolean
	{
		let regex = new RegExp('iPhone');
		let regexTwo = new RegExp('iOS');
		return this.isMobile() && (regex.test(this.osName) || regexTwo.test(this.osName));
	}

	public isIos(): boolean {
		let regex = new RegExp(/ipad|ipod|iphone/i);
		return regex.test(navigator.userAgent);
	}

	public isAndroid(): boolean
	{
		let regex = new RegExp('Android');
		return regex.test(this.osName);
	}

	public isWindows(): boolean
	{
		let regex = new RegExp('Windows|windows');
		return regex.test(this.osName);
	}
}
