
import {AbstractConstraint} from "../AbstractConstraint";
import {ConstraintInterface} from "../ConstraintInterface";

export class PhoneConstraint extends AbstractConstraint implements ConstraintInterface
{
	public static constraintName = "phone";
	// Allows one '+' at the start then numbers and spaces
	protected constraintRegex: RegExp = /^\+*[0-9|\ ]*$/;
	protected constrainteplaceRegex: RegExp = /[^0-9|\ |^\+]/;
}
