import {AbstractValidator} from "../AbstractValidator";

declare let $: any;

export class EmailMatchValidator extends AbstractValidator {

	public static validatorName: string = "emailMatch";

	public validate(): boolean
	{
		let inputElement = this.inputElement;
		let inputValue = inputElement.val();

		let target = $(this.inputElement).parents('form').find("[name='email']");

		return target.val() == inputValue;
	}
}
