import {DI} from "../../../DI";

export class Config
{
  private configArray: Object;
  private DI: DI;
  
  public constructor(
    configArray: Object,
    DI: DI
  )
  {
    this.configArray = configArray;
    this.DI = DI;
  }
  
  /**
   * @returns Object
   */
  public toArray(): Object | Array<any>
  {
    return this.configArray;
  }
  
  /**
   * @returns any
   */
  public getByPath(
    configPath: string,
    configArray: Object = null
  ): this | Array<any> | Object | boolean | string | number
  {
    if (!configArray)
    {
      configArray = this.configArray;
    }
    
    let configValue = this
      .getDI()
      .getConfigModule()
      .searchArray(configPath, configArray);
    
    if (
      Array.isArray(configValue)
      ||
      configValue instanceof Object
    )
    {
      return new Config(configValue, this.getDI());
    }
    
    return configValue;
  }
  
  private getDI(): DI
  {
    return this.DI;
  }
}
