
import {AbstractValidator} from "../AbstractValidator";

export class DummyValidator extends AbstractValidator {

	public static validatorName: string = "dummy";

	public validate(): boolean {

		return true;
	}
}
