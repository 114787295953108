import {DI} from "./DI";

export abstract class DIAware
{
	private DI: DI;

	getDI(): DI
	{
		return this.DI;
	}

	setDI(DI: DI): this
	{
		this.DI = DI;

		return this;
	}
}


