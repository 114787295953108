import {AbstractValidator} from "../AbstractValidator";

declare let $: any;

export class RequiredValidator extends AbstractValidator {
    public static validatorName: string = "required";

    public validate(): boolean {
        if (typeof this.inputElement.attr('type') !== 'undefined') {
            if (this.inputElement.attr('type') === "radio")
            {
                let fieldNameAttribute = this.inputElement.attr('name');
                let matchingRadioInputs = this.formComponent.find('[name="' + fieldNameAttribute + '"]');

                if (matchingRadioInputs.length > 1) {

                    for (let item of matchingRadioInputs) {

                        if ($(item).is(':checked')) {

                            return true;
                        }
                    }

                }
                else if ($(matchingRadioInputs[0]).is(':checked')) {

                    return true;
                }

                return false;
            }

            // If input element is a checkbox make sure it is ticked to return valid
            if (this.inputElement.attr('type') === "checkbox") {
                // returns true if checked
                return !!this.inputElement.is(':checked');
            }

            if(this.inputElement.attr('type') === 'file')
            {
                return this.inputElement.get(0).files.length > 0;
            }
        }

        // Get element tag name
        let tagName = (typeof this.inputElement[0].localName !== "undefined") ? this.inputElement[0].localName : this.inputElement[0].nodeName;

        if (tagName.toLowerCase() === "select") {
            // return true if the value is different from the placeholder
            return this.inputElement.val() !== this.inputElement.attr('placeholder');
        }

        if (Boolean(this.validateParam) === true) {
            // If placeholder exists
            if (this.inputElement.attr('placeholder')) {
                // If value is the same as placeholder
                if (this.inputElement.val() == this.inputElement.attr('placeholder')) {
                    return false;
                }
            }

            // If standard element just check value doesn't equal nothing
            return (this.getInputValue() !== "");
        } else {
            return true;
        }
    }
}
