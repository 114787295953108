
import {AbstractValidator} from "../AbstractValidator";

export class MinLengthValidator extends AbstractValidator
{
	public static validatorName: string = "minLength";

	public validate(): boolean
	{
		let inputValue = this.getInputValue();

		let inputLength = inputValue.length;

		// If nothing entered pass validation
		if (!inputLength) return true;

		// returns true if length is more than or equal to the param supplied
		return (inputLength >= parseInt(this.validateParam));
	}
}
