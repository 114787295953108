import {AbstractModule} from "../AbstractModule";

export class CookieModule extends AbstractModule {

    public setCookie(name: string, val: string, domain: string = null, expiryDate: Date = null)
    {
        let cookieString = name + "=" + val + ";path=/";

        if (domain)
        {
            domain = domain ? domain : '.' + window.location.hostname;

            cookieString += `;domain=${domain}`;
        }

        if(expiryDate)
        {
            cookieString +=  `;expires=${expiryDate.toUTCString()}`;
        }

        document.cookie = cookieString;
    }

    public getCookie(name: string): string|null
    {
        const value = "; " + document.cookie;
        const parts = value.split("; " + name + "=");

        if (parts.length == 2)
        {
            // @ts-ignore
            return parts.pop().split(";").shift();
        }

        return null;
    }

    public deleteCookie(name: string)
    {
        const date = new Date();
        date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));
        document.cookie = name + "=; expires=" + date.toUTCString() + "; path=/";
    }

}
