import {AbstractConstraint} from "../AbstractConstraint";
import {ConstraintInterface} from "../ConstraintInterface";

export class MoneyConstraint extends AbstractConstraint implements ConstraintInterface
{
	public static constraintName = "money";
	// Allowing select Currency symbols, commas, decimals, and numbers
	protected constraintRegex: RegExp = /^[$£€.,0-9]+$/;
	protected constraintReplaceRegex: RegExp = /[^$£€.,0-9]/;
}
