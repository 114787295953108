
import {AbstractValidator} from "../AbstractValidator";

export class EmailValidator extends AbstractValidator {

	public static validatorName: string = "email";

	public validate(): boolean {
		let inputValue = this.getInputValue();
		let inputLength = inputValue.length;

		// If nothing entered pass validation
		if (!inputLength) return true;

		// Regex for email validation from: http://emailregex.com/
		let emailRegEx = new RegExp("^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$", "i");

		// returns true if the value supplied matches the regex
		return (emailRegEx.test(inputValue));
	}
}
