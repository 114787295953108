import {AbstractValidator} from "../AbstractValidator";

declare let $: any;

export class NonCommonPasswordValidator extends AbstractValidator {

    public static validatorName: string = "nonCommonPassword";

    public validate(): boolean {
        return false;
    }

    public async asyncValidate(event): Promise<boolean>
    {
        let inputElement = this.inputElement;
        let inputValue = inputElement.val();

        let formData = new FormData();
        formData.append('password', inputValue);
        let endPoint = inputElement.parents('[non-common-password-endpoint]').attr('non-common-password-endpoint');

        let result = await fetch(endPoint, {
            method: 'POST',
            body: formData,
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
        })
            .then((response) => response.json())
            .then((response) => response.isValid)
            .catch(() => false);

        return await result;
    }

}