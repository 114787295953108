import {ConfigModule} from "./Modules/Config/ConfigModule";
import {GenerateModule} from "./Modules/Generate/GenerateModule";
import {UrlModule} from "./Modules/Url/UrlModule";
import {Config} from "./Modules/Config/Entity/Config";
import {UserAgentModule} from "./Modules/UserAgent/UserAgentModule";
import {CookieModule} from "./Modules/Cookie/CookieModule";
import {BreakPointModule} from "./Modules/Breakpoints/BreakPointModule";
import {UiBaseMainApp} from "ui-base/src/_resources/ts";
import {EnvironmentModule} from "./Modules/Environment/EnvironmentModule";

export class DI
{
  private configModule: ConfigModule;
  private generateModule: GenerateModule;
  private urlModule: UrlModule;
  private userAgentModule: UserAgentModule;
  private cookieModule: CookieModule;
  private breakPointModule: BreakPointModule;
  private app: UiBaseMainApp;
  private environmentModule: any;
  
  public getConfig(): Config
  {
    return this.getConfigModule().getConfig();
  }
  
  public getConfigModule(): ConfigModule
  {
    if (!this.configModule)
    {
      this.configModule = new ConfigModule(this);
    }
    
    return this.configModule;
  }
  
  public getGenerateModule(): GenerateModule
  {
    if (!this.generateModule) {
      
      this.generateModule = new GenerateModule(this);
    }
    
    return this.generateModule;
  }
  
  public getUrlModule(): UrlModule
  {
    if(!this.urlModule)
    {
      this.urlModule = new UrlModule(this);
    }
    
    return this.urlModule;
  }
  
  public getUserAgentModule(): UserAgentModule
  {
    if (!this.userAgentModule)
    {
      this.userAgentModule = new UserAgentModule();
    }
    
    return this.userAgentModule;
  }
  
  public getCookieModule(): CookieModule
  {
    if (!this.cookieModule)
    {
      this.cookieModule = new CookieModule(this);
    }
    
    return this.cookieModule;
  }
  
  public getBreakPointModule(): BreakPointModule
  {
    if (!this.breakPointModule)
    {
      this.breakPointModule = new BreakPointModule();
    }
    
    return this.breakPointModule;
  }
  
  public getApp(): UiBaseMainApp
  {
    return this.app;
  }
  
  public setApp(app: UiBaseMainApp): DI
  {
    this.app = app;
    return this;
  }
  
  public getEnvironmentModule(): EnvironmentModule
  {
    if (!this.environmentModule)
    {
      this.environmentModule = new EnvironmentModule(this);
    }
    
    return this.environmentModule;
  }
}
