
import {AbstractModule} from "../AbstractModule";
import {Environment} from "./Entity/Environment";

export class EnvironmentModule extends AbstractModule
{
	private environment: Environment;

	public getEnvironment(): Environment
	{
		if (!this.environment)
		{
			let envString = this
				.getDI()
				.getConfig()
				.getByPath('environment:env')
    .toString();

			this.environment = new Environment(envString);
		}

		return this.environment;
	}

	public getBrandName(pretty = true): string
	{
		let brandName = this.getDI().getConfig().getByPath('environment:brandName').toString();

		if (pretty) return brandName;

		return brandName.replace(/\s/g, "").toLowerCase();
	}

	public getDomain(pretty = false): string
	{
		return this.getDI().getConfig().getByPath('environment:domain').toString();
	}
}
